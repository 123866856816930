import React, { Component } from "react";
import TabsTwo from "../../../elements/tab/TabTwo";

class About extends Component {
	render() {
		let title = "About Me",
			description =
				"Self-taught developer and entrepreneur from an early age, I have always dedicated a vocation to advanced software engineering and technologies. From small structures to big companies, I will commit these solid experiences to your success.";
		return (
			<React.Fragment>
				<div name="about" className="about-wrapper">
					<div className="container">
						<div className="row row--35 align-items-center">
							<div className="col-lg-5 col-md-12">
								<div className="thumbnail">
									<img
										className="w-100"
										src="/assets/images/about/Camille-About-4.jpg"
										alt="About Images"
									/>
								</div>
							</div>

							<div className="col-lg-7 col-md-12">
								<div className="about-inner inner">
									<div className="section-title">
										<h2 className="title">{title}</h2>
										<p className="description">{description}</p>
									</div>
									<div className="row mt--30">
										<TabsTwo tabStyle="tab-style--1" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
export default About;
