import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

class TabsTwo extends Component {
	render() {
		let tab1 = "Main skills",
			tab3 = "Experience",
			tab4 = "Education & Certification";
		const { tabStyle } = this.props;
		return (
			<div>
				{/* Start Tabs Area */}
				<div className="tabs-area">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<Tabs>
									<TabList className={`${tabStyle}`}>
										<Tab>{tab1}</Tab>
										<Tab>{tab3}</Tab>
										<Tab>{tab4}</Tab>
									</TabList>

									<TabPanel>
										<div className="single-tab-content">
											<ul>
												<li>
													<b>
														Frond-end development <span> - Web & Mobile</span>
													</b>
													ReactJS and Native, Apollo, Redux, Vue.js, Material-ui
												</li>
												<li>
													<b>
														Back-end engineering
														<span> - Node & DB</span>
													</b>
													Express, Meteor, Cloud Functions, GraphQL, MongoDB,
													PostgreSQL
												</li>
												<li>
													<b>
														Infrastructure & DevOps <span> - CI/CD</span>
													</b>
													Git Workflow, Google Cloud, AWS, Docker, Kubernetes
												</li>
											</ul>
										</div>
									</TabPanel>

									<TabPanel>
										<div className="single-tab-content">
											<ul>
												<li>
													<b>
														Full-stack Engineer<span> - Freelance</span>
													</b>{" "}
													2017 - Current
												</li>
												<li>
													<b>
														Consultant<span> - Rai Lab</span>
													</b>{" "}
													2016 - 2017
												</li>
												<li>
													<b>
														Apprentice Developer<span> - IBM Switzerland</span>
													</b>{" "}
													2012 - 2016
												</li>
											</ul>
										</div>
									</TabPanel>

									<TabPanel>
										<div className="single-tab-content">
											<ul>
												<li>
													<b>
														BSc Business Information Technologies
														<span> - Haaga-Helia, Finland</span>
													</b>{" "}
													2019 - 2020
												</li>
												<li>
													<b>
														BSc Business Information Systems
														<span>
															{" "}
															- School of Business Administration Geneva
														</span>
													</b>{" "}
													2017 - 2020
												</li>
												<li>
													<b>
														Federal Certificate of Proficiency In CS
														<span> - IBM</span>
													</b>{" "}
													2012 - 2016
												</li>
											</ul>
										</div>
									</TabPanel>
								</Tabs>
							</div>
						</div>
					</div>
				</div>
				{/* End Tabs Area */}
			</div>
		);
	}
}

export default TabsTwo;
