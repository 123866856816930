import React, { Component } from "react";
import { Link } from "react-scroll";
import { FiLayers, FiServer, FiShield, FiLayout } from "react-icons/fi";

const ServiceList = [
	{
		icon: <FiLayers />,
		title: "App Development",
		description:
			"I will develop for you robust and efficient applications with the latest cross-platform technologies.",
	},
	{
		icon: <FiLayout />,
		title: "UI/UX Design",
		description:
			"My 8+ years of experience in building interfaces will contribute to perfect your user experience.",
	},
	{
		icon: <FiShield />,
		title: "Software Security",
		description:
			"With strict auditing, I will ensure that your clients are using your products under secure conditions.",
	},
	{
		icon: <FiServer />,
		title: "Infrastructure & DevOps",
		description:
			"From development to production, I will automate and unify your deployment processes.",
	},
];

class ServiceTwo extends Component {
	render() {
		let title = "My Services",
			description =
				"As a versatile freelancer, I offer a wide variety of services. Please share with me your most challenging concepts.";
		return (
			<React.Fragment>
				<div className="row" name="services">
					<div className="col-lg-4 col-12">
						<div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
							<h2 className="title">{title}</h2>
							<p>{description}</p>
							<div className="service-btn">
								<Link
									spy={true}
									smooth={true}
									duration={500}
									offset={this.props.isDesktop ? -200 : -10}
									to="contact"
									className="btn-transparent rn-btn-dark"
								>
									<span className="text">Request Custom Service</span>
								</Link>
							</div>
						</div>
					</div>
					<div className="col-lg-8 col-12 mt_md--50">
						<div className="row service-one-wrapper">
							{ServiceList.map((val, i) => (
								<div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
									<Link
										spy={true}
										smooth={true}
										duration={500}
										offset={this.props.isDesktop ? -200 : -10}
										to="contact"
									>
										<div className="service service__style--2">
											<div className="icon">{val.icon}</div>
											<div className="content">
												<h3 className="title">{val.title}</h3>
												<p>{val.description}</p>
											</div>
										</div>
									</Link>
								</div>
							))}
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
export default ServiceTwo;
