import React, { Component } from "react";
import { FiCode, FiBox, FiGitPullRequest } from "react-icons/fi";

const ServiceList = [
	{
		icon: <FiBox />,
		title: "Software Architecture",
		description:
			"A robust and well-designed architecture is essential to make your solutions flexible and secure.",
	},
	{
		icon: <FiCode />,
		title: "App Development",
		description:
			"Take advantage of an experienced and up to date developer to keep your product on top of the technology.",
	},
	{
		icon: <FiGitPullRequest />,
		title: "Infrastructure & DevOps",
		description:
			"Bring your craziest ideas to market and enhance your deployment speed.",
	},
];

class ServiceOne extends Component {
	render() {
		return (
			<React.Fragment>
				<div className="row">
					{ServiceList.map((val, i) => (
						<div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
							<div className="service service__style--1">
								<div className="icon">{val.icon}</div>
								<div className="content">
									<h4 className="title">{val.title}</h4>
									<p>{val.description}</p>
								</div>
							</div>
						</div>
					))}
				</div>
			</React.Fragment>
		);
	}
}
export default ServiceOne;
