import React, { Component } from "react";

import SliderOne from "../component/slider/SliderOne";
import ServiceTwo from "../elements/service/ServiceTwo";
import About from "../component/HomeLayout/homeOne/About";
import Portfolio from "../component/HomeLayout/homeOne/Portfolio";
import BrandTwo from "../elements/BrandTwo";
import ContactOne from "../elements/contact/ContactOne";

//const ServiceTwo = loadable(() => import("../elements/service/ServiceTwo"));
//const About = loadable(() => import("../component/HomeLayout/homeOne/About"));
//const Portfolio = loadable(() =>
//	import("../component/HomeLayout/homeOne/Portfolio")
//);
//const BrandTwo = loadable(() => import("../elements/BrandTwo"));
//const ContactOne = loadable(() => import("../elements/contact/ContactOne"));

class MainDemo extends Component {
	render() {
		return (
			<div className="active-dark">
				{/* Start Slider Area   */}
				<div className="slider-wrapper">
					<SliderOne />
				</div>
				{/* End Slider Area   */}

				{/* Start About Area */}
				<div className="about-area about-position-top pb--120 bg_color--1">
					<About />
				</div>
				{/* End About Area */}

				{/* Start Service Area  */}
				<div className="service-area ptb--80  bg_image bg_image--3">
					<div className="container">
						<ServiceTwo />
					</div>
				</div>
				{/* End Service Area  */}

				{/* Start Portfolio Area */}
				<div className="portfolio-area pb--70 pt--120 bg_color--1">
					<div className="portfolio-sacousel-inner mb--55">
						<Portfolio />
					</div>
				</div>
				{/* End Portfolio Area */}

				{/* Start Brand Area */}
				<div className="rn-brand-area bg_color--1 pb--80">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<BrandTwo />
							</div>
						</div>
					</div>
				</div>
				{/* End Brand Area */}
				<div className="service-area ptb--80  bg_image bg_image--3">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<ContactOne />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default MainDemo;
