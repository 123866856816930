import React, { Component } from "react";
import { Link } from "react-scroll";
import { portfolioSlick2 } from "../../../page-demo/script";
import "../../../setupTests";

import Slider from "react-slick";

const PortfolioList = [
	{
		image: "image-1",
		category: "Web Development",
		title: "SplitFare Desktop Web Application",
	},
	{
		image: "image-2",
		category: "Internal Platform",
		title: "Influencers Agency Platform",
	},
	{
		image: "image-3",
		category: "Mobile Application",
		title: "SplitFare Mobile Cross-platform",
	},
];

class Portfolio extends Component {
	render() {
		let title = "My Portfolio",
			description =
				"They trust me, and they don't regret it. A brief sample of my past realisations may convince you more than a thousand words.";
		return (
			<React.Fragment>
				<div className="portfolio-wrapper" name="potfolio">
					<div className="container">
						<div className="row">
							<div className="col-lg-6">
								<div className="section-title">
									<h2>{title}</h2>
									<p>{description}</p>
								</div>
							</div>
						</div>
					</div>
					<div className="portfolio-slick-activation mt--70 mt_sm--40">
						<Slider {...portfolioSlick2}>
							{PortfolioList.map((value, index) => (
								<div className="portfolio" key={index}>
									<div className="thumbnail-inner">
										<div className={`thumbnail ${value.image}`}></div>
										<div className={`bg-blr-image ${value.image}`}></div>
									</div>
									<div className="content">
										<div className="inner">
											<p>{value.category}</p>
											<h4>{value.title}</h4>
											<div className="portfolio-button">
												<Link
													spy={true}
													smooth={true}
													duration={500}
													offset={this.props.isDesktop ? -200 : -10}
													to="contact"
													className="rn-btn"
												>
													Details
												</Link>
											</div>
										</div>
									</div>
								</div>
							))}
						</Slider>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
export default Portfolio;
