import React, { Component } from "react";

class BrandTwo extends Component {
	render() {
		return (
			<React.Fragment>
				<ul className="brand-style-2">
					<li>
						<img src="/assets/images/brand/IBM_logo.svg" alt="Logo Images" />
					</li>
					<li>
						<img
							src="/assets/images/brand/Raiffeisen_Schweiz_Logo.svg"
							alt="Logo Images"
						/>
					</li>
					<li>
						<img
							src="/assets/images/brand/bornmkg_logo.svg"
							alt="Logo Images"
						/>
					</li>
				</ul>
			</React.Fragment>
		);
	}
}
export default BrandTwo;
