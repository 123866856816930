import React, { Component } from "react";
import analytics from "../../data/Analytics";
import { validate } from "email-validator";

import {
	AiFillGithub,
	AiFillLinkedin,
	AiOutlineGitlab,
	AiOutlineTwitter,
} from "react-icons/ai";

import { FiCheckCircle, FiXCircle } from "react-icons/fi";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import Loader from "react-loader-spinner";

const formResponses = [
	{
		code: "success",
		icon: <FiCheckCircle />,
		message: (
			<>
				Your message was sent successfully!
				<br />I will answer you shortly.
			</>
		),
	},
	{
		code: "error",
		icon: <FiXCircle />,
		message: (
			<>
				Oups, there was an error.
				<br />
				Please check all fields.
			</>
		),
	},
];

const encode = (data) => {
	return Object.keys(data)
		.map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
		.join("&");
};

class ContactOne extends Component {
	constructor(props) {
		super(props);
		this.state = {
			rnName: "",
			rnEmail: "",
			rnSubject: "",
			rnMessage: "",
			rows: 4,
			status: "writing",
		};
	}

	submitForm = async (e) => {
		e.preventDefault();
		if (
			this.state.rnName !== "" &&
			this.state.rnMessage !== "" &&
			this.state.rnSubject !== "" &&
			validate(this.state.rnEmail)
		) {
			this.setState({ status: "sending" });
			fetch("/", {
				method: "POST",
				headers: { "Content-Type": "application/x-www-form-urlencoded" },
				body: encode({
					"form-name": "contact",
					name: this.state.rnName,
					email: this.state.rnEmail,
					subject: this.state.rnSubject,
					message: this.state.rnMessage,
				}),
			})
				.then(() => {
					this.setState({ status: "success" });
					analytics.event({
						category: "User",
						action: "Contact Sent",
					});
				})
				.catch((error) => {
					this.setState({ status: "error" });
					analytics.event({
						category: "User",
						action: "Contact Error",
					});
				});
		} else {
			this.setState({ status: "error" });
			analytics.event({
				category: "User",
				action: "Contact Error",
			});
		}
	};

	render() {
		return (
			<div name="contact" className="contact-form--1">
				<div className="container">
					<div className="row row--35 align-items-start">
						<div className="col-lg-6 order-1 order-lg-1">
							<div className="section-title text-left mb--50">
								<h2 className="title">Hire Me</h2>
								<p className="description">
									Now it's time for me to hear from you. Connect us via this
									contact form or through my social media.
								</p>
								<span className="contact-separator"></span>
								<nav className="mainmenunav">
									<ul className="mainmenu socialmedia">
										<li>
											<a
												href="https://www.linkedin.com/in/oggiercamille/"
												target="_blank"
												rel="noopener noreferrer"
											>
												<AiFillLinkedin />
											</a>
										</li>
										<li>
											<a
												href="https://twitter.com/SwissWar"
												target="_blank"
												rel="noopener noreferrer"
											>
												<AiOutlineTwitter />
											</a>
										</li>
										<li>
											<a
												href="https://github.com/camogg"
												target="_blank"
												rel="noopener noreferrer"
											>
												<AiFillGithub />
											</a>
										</li>
										<li>
											<a
												href="https://gitlab.com/camogg"
												target="_blank"
												rel="noopener noreferrer"
											>
												<AiOutlineGitlab />
											</a>
										</li>
									</ul>
								</nav>
							</div>
						</div>
						<div className="col-lg-6 order-2 order-lg-2">
							{this.state.status !== "writing" &&
								this.state.status !== "sending" && (
									<div className="status">
										<span>
											{
												formResponses.find(
													(response) => response.code === this.state.status
												).icon
											}
										</span>
										{
											formResponses.find(
												(response) => response.code === this.state.status
											).message
										}
									</div>
								)}
							<div className="form-wrapper">
								<form>
									<label htmlFor="item01">
										<input
											type="text"
											name="name"
											id="item01"
											value={this.state.rnName}
											onChange={(e) => {
												this.setState({ rnName: e.target.value });
											}}
											placeholder="Your Name"
										/>
									</label>

									<label htmlFor="item02">
										<input
											type="text"
											name="email"
											id="item02"
											value={this.state.rnEmail}
											onChange={(e) => {
												this.setState({ rnEmail: e.target.value });
											}}
											placeholder="Your email"
										/>
									</label>

									<label htmlFor="item03">
										<input
											type="text"
											name="subject"
											id="item03"
											value={this.state.rnSubject}
											onChange={(e) => {
												this.setState({ rnSubject: e.target.value });
											}}
											placeholder="Write a Subject"
										/>
									</label>
									<label htmlFor="item04">
										<textarea
											type="text"
											id="item04"
											name="message"
											rows={this.state.rows}
											value={this.state.rnMessage}
											onChange={(e) => {
												const lines = e.target.value.split(/\r*\n/).length;
												this.setState({
													rnMessage: e.target.value,
													rows: lines > 4 ? lines : 4,
												});
											}}
											placeholder="Your Message"
										/>
									</label>
									<div className="button-wrapper">
										<button
											className="rn-button-style--2 btn-solid"
											type="submit"
											value="submit"
											name="submit"
											id="mc-embedded-subscribe"
											onClick={this.submitForm}
											disabled={this.state.status === "sending" ? true : false}
										>
											Submit
										</button>
										{this.state.status === "sending" && (
											<Loader
												className="loader"
												type="TailSpin"
												height={30}
												width={30}
												color="#c7c9d8"
											/>
										)}
									</div>
								</form>
							</div>
						</div>
					</div>
					<div className="row row--35 align-items-start">
						<div className="col-lg-6 order-1 order-lg-1 ">
							<div className="section-title text-left">
								<p className="description copyrights">
									© Copyright {new Date().getFullYear()} Camille Oggier
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default ContactOne;
