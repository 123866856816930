import React, { Component } from "react";
import ServiceOne from "../../elements/service/ServiceOne";
import TextLoop from "react-text-loop";

const SlideList = {
	textPosition: "text-left",
	category: "",
	description: "",
	buttonText: "",
	buttonLink: "",
};

class SliderOne extends Component {
	render() {
		return (
			<div className="slider-activation">
				{/* Start Single Slide */}
				<div
					className="slide personal-portfolio-slider slider-style-3 slider-fixed--height d-flex align-items-center bg_image bg_image--1"
					data-black-overlay="6"
				>
					<div className="container position-relative">
						<div className="row">
							<div className="col-lg-12">
								<div className="inner">
									{/*<h1 className="title theme-gradient">
										{"Camille"} <br /> {"Oggier"} </h1> */}
									<div className={`inner ${SlideList.textPosition}`}>
										{SlideList.category ? (
											<span>{SlideList.category}</span>
										) : (
											""
										)}
										<h1 className="title">
											Hi, I’m <br />
											<span className="theme-gradient">Camille Oggier</span>
											<br />
											<TextLoop>
												<h1 className="title"> JS Developer</h1>
												<h1 className="title"> UI/UX Ninja</h1>
												<h1 className="title"> Freelancer</h1>
											</TextLoop>{" "}
										</h1>
										<h2>based in Switzerland.</h2>
										{SlideList.description ? (
											<p className="description">{SlideList.description}</p>
										) : (
											""
										)}
										{SlideList.buttonText ? (
											<div className="slide-btn">
												<a
													className="rn-button-style--2 btn-primary-color"
													href={`${SlideList.buttonLink}`}
												>
													{SlideList.buttonText}
												</a>
											</div>
										) : (
											""
										)}
									</div>
								</div>
							</div>
						</div>
						{/* Start Service Area */}
						<div className="service-wrapper service-white">
							<ServiceOne />
						</div>
						{/* End Service Area */}
					</div>
				</div>
				{/* End Single Slide */}
			</div>
		);
	}
}
export default SliderOne;
