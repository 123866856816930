// React Required
import React, { Component } from "react";
import { render } from "react-snapshot";

// Create Import File
import "./index.scss";

// Common Layout
import Layout from "./component/common/App";

// Home layout

// Dark Home Layout
import DarkMainDemo from "./dark/MainDemo";

// Element Layout
import * as serviceWorker from "./serviceWorker";

class Root extends Component {
	render() {
		return (
			<Layout>
				<DarkMainDemo />
			</Layout>
		);
	}
}

render(<Root />, document.getElementById("root"));
serviceWorker.unregister();
