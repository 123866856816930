import React, { Component } from "react";
import { Link } from "react-scroll";
import withSizes from "react-sizes";
import { FiX, FiMenu } from "react-icons/fi";

class Header extends Component {
	constructor(props) {
		super(props);
		this.menuTrigger = this.menuTrigger.bind(this);
		this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
		//  this.subMetuTrigger = this.subMetuTrigger.bind(this);
		window.addEventListener("load", function () {
			//assets loaded
		});
	}

	menuTrigger() {
		document.querySelector(".header-wrapper").classList.toggle("menu-open");
	}

	CLoseMenuTrigger() {
		document.querySelector(".header-wrapper").classList.remove("menu-open");
	}

	render() {
		var elements = document.querySelectorAll(".has-droupdown > a");
		for (var i in elements) {
			if (elements.hasOwnProperty(i)) {
				elements[i].onclick = function () {
					this.parentElement
						.querySelector(".submenu")
						.classList.toggle("active");
					this.classList.toggle("open");
				};
			}
		}
		const { color = "default-color" } = this.props;

		return (
			<header
				className={`header-area formobile-menu header--transparent ${color}`}
			>
				<div className="header-wrapper" id="header-wrapper">
					<div className="header-left">
						{/*
                        <div className="logo">
                            <a href="/">
                                {logoUrl}
                            </a>
                        </div>*/}
					</div>
					<div className="header-right">
						<nav className="mainmenunav d-lg-block">
							<ul className="mainmenu">
								<li>
									<Link
										spy={true}
										smooth={true}
										duration={500}
										offset={this.props.isDesktop ? -200 : -10}
										to="about"
										onClick={(_) => this.CLoseMenuTrigger()}
									>
										About
									</Link>
								</li>
								<li>
									<Link
										spy={true}
										smooth={true}
										duration={500}
										offset={this.props.isDesktop ? -220 : -10}
										to="services"
										onClick={(_) => this.CLoseMenuTrigger()}
									>
										Services
									</Link>
								</li>
								<li>
									<Link
										spy={true}
										smooth={true}
										duration={500}
										offset={this.props.isDesktop ? -100 : -10}
										to="potfolio"
										onClick={(_) => this.CLoseMenuTrigger()}
									>
										Portfolio
									</Link>
								</li>
								<li>
									<Link
										spy={true}
										smooth={true}
										duration={500}
										offset={this.props.isDesktop ? -200 : -10}
										to="contact"
										onClick={(_) => this.CLoseMenuTrigger()}
									>
										Contact
									</Link>
								</li>
							</ul>
						</nav>
						{/* Start Humberger Menu  */}
						<div className="humberger-menu d-block d-lg-none pl--20">
							<span
								onClick={this.menuTrigger}
								className="menutrigger text-white"
							>
								<FiMenu />
							</span>
						</div>
						{/* End Humberger Menu  */}
						<div className="close-menu d-block d-lg-none">
							<span onClick={this.CLoseMenuTrigger} className="closeTrigger">
								<FiX />
							</span>
						</div>
					</div>
				</div>
			</header>
		);
	}
}

const mapSizesToProps = (sizes) => ({
	isMobile: withSizes.isMobile(sizes),
	isDesktop: withSizes.isDesktop(sizes),
});

export default withSizes(mapSizesToProps)(Header);
